import React from 'react';
import ReactDOM from 'react-dom/client';
import Env from '@gatec/core/env';
import { GatecApp, GatecRoute } from '@gatec/ui/app';
import { SkeletonLoading } from '@gatec/ui/layout/skeleton';
import LoginDefault from '@gatec/ui/pages/auth/login';
import LoginSSO from '@gatec/ui/pages/auth/sso';
import { GatecProvider } from '@gatec/ui/provider';
import { Config } from './config';
import { Menu } from './menu';

const root = ReactDOM.createRoot(
  document.getElementById('root'),
);

const Login = () => (Env.GATEC_APP_SSO
  ? <LoginSSO endpoint="Authentication/AuthenticateWithSSO" />
  : <LoginDefault endpoint="Authentication/Authenticate" productLogo="/images/logos/product-logo.png" />);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<SkeletonLoading />}>
      <GatecProvider>
        <GatecApp
          menuProps={Menu}
          configProps={Config}
        >
          <GatecRoute key="login" path="/login" element={<Login />} />
        </GatecApp>
      </GatecProvider>
    </React.Suspense>
  </React.StrictMode>
  ,
);
